/** @jsx jsx */

import PropTypes from 'prop-types'
import Link from 'gatsby-link';
import { css, jsx } from '@emotion/react'
import { colors, shadeColor } from '../../styles/colors'
let styles;
const Button = props => {
  if (props.internal_path) {
    return <Link to={props.internal_path} css={[styles.button, ((props.color && styles[props.color]) ? styles[props.color] : ''), 'button']}>{props.title}</Link>
  } else {
    return <a href={props.external_path} onClick={props.action} css={[styles.button, ((props.color && styles[props.color]) ? styles[props.color] : ''), 'button']}>
      {props.title}
    </a>
  }
};
Button.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.func,
  // Valid values are: "blue".
  color: PropTypes.oneOf(['default', 'blue']),
  external_path: PropTypes.string,
  internal_path: PropTypes.string,
};
styles = {
  button: css`
    background-color: ${shadeColor(colors.blueBunting, 20)};
    border: 0;
    border-radius: 5px;
    color: ${colors.white};
    display: inline-block;
    font-weight: bold;
    margin: 0 auto;
    max-width: 400px;
    padding: 1em;
    text-decoration: none;
    :hover {
      background-color: ${colors.lavenderMagenta};
      cursor: pointer;
    }
  `,
  blue: css`
    background-color: ${colors.blueRoyal};
    color: ${colors.white};
    :hover {
      background-color: ${colors.blueMalibu};
    }
  `
};
export default Button;
